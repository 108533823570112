import React from "react";
import { Link } from "react-router-dom";
import img from "./img/1.png";
import img2 from "./img/2.png";
import bg from "./img/campaign-bg.png";
const Campaign = () => {
	const [open, setOpen] = React.useState(false);
	return (
		<section
			className="pt-10 sm:pt-20 2xl:pt-[160px] pb-2 sm:pb-20 md:pb-0"
			onMouseOver={() => setOpen(true)}
			style={{
				background: `url(${bg}) no-repeat center center / cover`,
			}}
		>
			<div className="container 2xl:max-w-[1480px]">
				<div className="flex flex-wrap-reverse lg:flex-wrap-reverse gap-4 gap-y-10">
					<div className="w-full 2xl:items-start items-center max-w-[200px] lg:max-w-[500px] xl:max-w-[531px] 2xl:translate-x-2 max-sm:max-w-full">
						<img
							src={open ? img2 : img}
							alt=""
							className="max-sm:w-[unset] max-sm:h-[200px] max-h-[576px]"
						/>
					</div>
					<div className="w-full sm:w-0 flex-grow xl:max-w-[913px] 2xl:translate-y-4">
						<h2 className="text-xl sm:text-2xl font-bold mb-5 md:text-4xl xl:text-[40px] xl:leading-[49px]">
							Comienza tu{" "}
							<span className="inline-block bg-primary text-dark rounded-md md:rounded-[15px] px-3 pb-1">
								campaña
							</span>
						</h2>
						<div className="sm:text-md xl:text-lg 2xl:text-xl 2xl:leading-[1.5] font-medium md:pb-20 2xl:pb-[126px]">
							<p className="mb-8 xl:mb-[124px] 2xl:mb-[184px]">
								Transformamos la visibilidad de tu marca con estrategias
								publicitarias innovadoras y ubicaciones estratégicas que
								garantizan un impacto memorable y efectivo.
							</p>
							<Link
								to="/contacto"
								className="btn text-xs px-4 md:text-md md:h-[50px] md:min-w-[280px] lg:text-[22px] lg:h-[60px] lg:min-w-[380px]"
							>
								Comenzar campaña
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Campaign;
