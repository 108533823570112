import React from "react";
import { Link } from "react-router-dom";

const Outdoor = () => {
	return (
		<section className="pt-[102px] pb-[99px] relative overflow-hidden">
			<div className="container relative z-[11]">
				<h1 className="text-4xl sm:text-5xl md:text-6xl xl:text-[50px] xl:leading-[64px] 2xl:text-[64px] 2xl:leading-[78px] font-bold mb-12 lg:mb-[98px] flex flex-wrap gap-8 items-center">
					<div className="bg-primary rounded-lg lg:rounded-[20px] inline-block px-4">
						Outdoor
					</div>
					<div className="text-normal md:text-md leading-[22px] font-medium w-[280px] flex-grow max-w-[968px]">
						Nuestros medios outdoor abarcan una amplia gama de soluciones
						publicitarias estratégicamente ubicadas para maximizar la
						visibilidad de tu marca. Cada opción está diseñada para
						capturar la atención del público objetivo en movimiento,
						garantizando que tu mensaje no solo sea visto, sino memorable.
					</div>
				</h1>
				<div className="flex flex-wrap gap-[22px] justify-evenly">
					{data.map((item, index) => (
						<div
							key={index}
							className="w-[calc(50%-11px)] lg:w-[calc((100%/3)-15px)] outdoor-item"
						>
							<Link className="outdoor-item-inner group" to="/proyectos">
								<img
									src={item.img1}
									alt=""
									className="w-full group-hover:opacity-0 transition-opacity duration-300"
								/>
								<img
									src={item.img2}
									alt=""
									className="w-full absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
								/>
							</Link>
						</div>
					))}
				</div>
			</div>
			<div className="absolute inset-0 before:absolute before:inset-0 before:z-10">
				<img
					src="/img/proyectos/outdoor-2.png"
					alt=""
					className="absolute left-0 top-0 min-h-full w-1/2 max-w-[690px] object-cover object-right-top hidden md:block"
				/>
			</div>
		</section>
	);
};
const data = [
	{
		img1: "/img/outdoor/1.png",
		img2: "/img/outdoor/hover-1.png",
	},
	{
		img1: "/img/outdoor/2.png",
		img2: "/img/outdoor/hover-2.png",
	},
	{
		img1: "/img/outdoor/3.png",
		img2: "/img/outdoor/hover-3.png",
	},
	{
		img1: "/img/outdoor/4.png",
		img2: "/img/outdoor/hover-4.png",
	},
	{
		img1: "/img/outdoor/5.png",
		img2: "/img/outdoor/hover-5.png",
	},
	{
		img1: "/img/outdoor/6.png",
		img2: "/img/outdoor/hover-6.png",
	},
];
export default Outdoor;
