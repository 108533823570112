import React from "react";

const History = () => {
	return (
		<section className="relative xl:pt-[228px] lg:py-[125px] py-20 overflow-hidden">
			<div className="container relative z-10">
				<div className="flex flex-wrap gap-12 xl:gap-[116px]">
					<div className="w-full lg:max-w-[400px] xl:max-w-[573px]">
						<h2 className="text-xl sm:text-2xl font-bold mb-8 md:text-5xl lg:text-6xl xl:text-[64px] xl:leading-[1.5]">
							Nuestras historias de{" "}
							<span className="text-primary">éxito</span>
						</h2>
						<div className="sm:text-md md:text-lg xl:text-xl font-medium">
							<p className="mb-6">
								En IMJ Media, iniciamos con un análisis detallado de las
								necesidades del cliente para asesorarlo en la elección
								de los medios más efectivos según sus objetivos
							</p>
							<p className="mb-6">
								Nuestro equipo de diseñadores propone adaptaciones
								visuales para asegurar la calidad y el impacto de los
								medios.
							</p>
							<p>
								Monitoreamos la planeación, implementación y
								seguimiento, brindando al cliente la posibilidad de
								observar el estatus de su campaña en tiempo real a
								través de OBP.
							</p>
						</div>
					</div>
					<div className="w-0 flex-grow flex flex-col gap-10 xl:gap-[67px] max-w-[785px]">
						{data.map((item, index) => (
							<div
								key={index}
								className="flex gap-5 2xl:gap-[37px] items-center relative"
							>
								<div className="w-[80px] h-[80px] sm:w-[150px] sm:h-[150px] relative">
									<img
										src={item.img}
										alt=""
										className="w-full h-full object-cover"
									/>
								</div>
								<div className="w-0 flex-grow">
									<h3 className="text-md sm:text-xl 2xl:text-[36px] 2xl:leading-[44px] font-bold py-[3px] px-[13px] rounded-[10px] bg-primary inline-block">
										{item.name}
									</h3>
									<p className="sm:text-md xl:text-xl font-bold">
										{item.title}
									</p>
									<p className="font-medium text-sm md:text-md 2xl:text-xl">
										{item.text}
									</p>
								</div>
								<img
									src={item.bg}
									className="absolute top-1/2 -translate-y-1/2 -z-10 left-[54%]"
									alt=""
								/>
							</div>
						))}
					</div>
				</div>
			</div>
			<div
				className="w-[40%] max-w-[693px] h-full absolute top-0 left-0"
				style={{
					background:
						"url(/img/about/history-shape.png) repeat-y top right / contain",
				}}
			></div>
		</section>
	);
};

const data = [
	{
		img: "/img/about/history/netflix.png",
		bg: "/img/about/history/n1.png",
		name: "Netflix",
		title: "Plataforma de streaming",
		text: "Implementamos con éxito una activación dentro la Friki Plaza de CDMX para promocionar el lanzamiento de One Piece.",
	},
	{
		img: "/img/about/history/televisa.png",
		bg: "/img/about/history/n2.png",
		name: "Televisa",
		title: "Televisora líder en México",
		text: 'Utilizamos luces led para hacer que un anuncio de "El Maleficio" fuera realmente espectacular.',
	},
	{
		img: "/img/about/history/volkswagen.png",
		bg: "/img/about/history/n3.png",
		name: "Volkswagen",
		title: "Agencia de coches",
		text: "Destacamos una campaña de Tiguan con una implementación que simulaba tener los faros de la camioneta encendidos",
	},
];

export default History;
