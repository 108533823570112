import React from "react";

const QuienesSomos = () => {
	return (
		<section className="pt-20 pb-[200px] lg:py-20 xl:pt-[160px] 2xl:pt-[200px] xl:pb-[176px] relative overflow-hidden">
			<div className="container 2xl:max-w-[1480px] relative z-10">
				<div className="w-full ml-auto lg:max-w-[500px] xl:max-w-[668px] 2xl:max-w-[913px]">
					<h2 className="text-xl sm:text-2xl font-bold mb-5 md:text-4xl xl:text-[40px] xl:leading-[49px] md:mb-7">
						¿Quiénes somos?
					</h2>
					<div className="sm:text-md xl:text-xl font-medium">
						<p className="mb-6">
							Somos una empresa tecnológica que ofrece soluciones en
							publicidad Outdoor e Indoor. Desde hace 18 años nos hemos
							dedicado a expandir nuestra oferta y cobertura, ofreciendo
							a las marcas los mejores medios para que sus campañas
							logren conectar con la mayor cantidad de audiencia.
						</p>
						<p>
							Nuestro compromiso con el crecimiento y la innovación nos
							ha permitido ser pioneros en el desarrollo de herramientas
							como Out Of Home Bit Planner, asegurando que cada campaña
							sea planeada y ejecutada de la mejor manera según las
							necesidades del cliente.
						</p>
					</div>
				</div>
			</div>
			<img
				className="absolute -bottom-1 right-4 lg:right-unset lg:left-0 xl:w-[40%] 2xl:w-[37%] max-xl:w-[500px] max-lg:w-[250px] max-sm:w-[200px] z-[11]"
				src="/img/about/about.png"
				alt=""
			/>
		</section>
	);
};

export default QuienesSomos;
