import React from "react";

const Innovacion = () => {
	return (
		<section className="py-20 md:pt-[94px] md:pb-[101px] relative overflow-hidden">
			<div className="container relative z-10">
				<div className="flex flex-wrap items-center gap-10">
					<div className="w-full md:w-[40%] md:max-w-[514px] flex flex-col gap-6 xl:gap-8 2xl:gap-[42px]">
						{data.map((item, index) => (
							<div key={index}>
								<img
									src={item.img}
									className="w-full border-[3px] rounded-[25px] border-primary"
									alt=""
								/>
							</div>
						))}
					</div>
					<div className="w-0 flex-grow">
						<div className="w-full ml-auto lg:max-w-[500px] xl:max-w-[838px]">
							<h2 className="text-xl sm:text-2xl font-bold mb-5 md:text-4xl xl:text-[40px] xl:leading-[49px] md:mb-7">
								<div>Innovación para </div>
								<span className="bg-primary rounded-[10px] px-2">
									tu marca en todo lugar
								</span>
							</h2>
							<div className="sm:text-md xl:text-xl font-medium">
								<p className="mb-6 md:mb-[34px]">
									En el dinámico entorno actual, donde la saturación de
									anuncios complica que las marcas se destaquen, es
									crucial encontrar nuevas maneras de capturar la
									atención del público. Nuestra avanzada tecnología y
									diversidad de medios, tanto en espacios interiores
									como exteriores, ofrecen soluciones efectivas para
									superar estos retos.
								</p>
								<strong className="xl:text-[22px]">
									Tecnología en publicidad estratégica
								</strong>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="absolute top-0 left-0 h-full w-[45%] max-w-[690px] hidden md:block"
				style={{
					background: `url("/img/proyectos/outdoor.png") repeat-x top right / contain`,
				}}
			></div>
		</section>
	);
};

const data = [
	{
		img: "/img/about/innovacion/1.png",
	},
	{
		img: "/img/about/innovacion/2.png",
	},
	{
		img: "/img/about/innovacion/3.png",
	},
	{
		img: "/img/about/innovacion/4.png",
	},
];

export default Innovacion;
