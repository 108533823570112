import React from "react";
import { Link } from "react-router-dom";
import { icons } from "./Icons";

const Header = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <header className="bg-title py-6 xl:py-[46px] relative z-[99]">
      <div className="px-4">
        <div className="hidden xl:flex items-center justify-around xl:text-md">
          <div className="xl:w-0 flex-grow xl:flex items-center justify-between xl:max-w-[550px]">
            <Menu1 />
          </div>
          <Link to="/" className="block xl:mx-10">
            <img src="/img/logo.png" alt="" />
          </Link>
          <div className="xl:w-0 flex-grow xl:flex items-center justify-between xl:max-w-[550px]">
            <Menu2 />
            <Link
              to="/contacto"
              className="btn xl:min-w-[240px] text-normal xl:text-md md:py-2 md:min-h-[50px] hover:bg-white hover:text-title"
            >
              Comienza tu campaña
            </Link>
          </div>
        </div>
        <div className="flex gap-3 items-center justify-between xl:hidden">
          <Link to="/" className="block max-w-[60px] me-auto">
            <img src="/img/logo.png" className="max-w-full" alt="" />
          </Link>
          <Link
            to="/contacto"
            className="btn text-xs md:text-normal hover:bg-white hover:text-title"
          >
            Comienza tu campaña
          </Link>
          <button
            type="button"
            className="text-white"
            onClick={() => setOpen(!open)}
          >
            {icons.hamburger}
          </button>
        </div>
        <div
          className={`xl:hidden fixed top-0 left-0 w-full h-full duration-300 transition-all ${
            open ? "visible opacity-100" : "invisible opacity-0"
          }`}
        >
          <div
            className="absolute inset-0 bg-title bg-opacity-60"
            onClick={() => setOpen(false)}
          ></div>
          <div
            className={`max-w-[260px] bg-title h-full relative z-1 duration-300 ${
              !open ? "-translate-x-full" : ""
            }`}
          >
            <button
              type="button"
              className="text-white absolute z-1 right-4 top-4"
              onClick={() => setOpen(false)}
            >
              {icons.close}
            </button>
            <div className="p-6 py-10 flex flex-col gap-3">
              <Link to="/" className="block max-w-[60px] me-auto mb-10">
                <img src="/img/logo.png" className="max-w-full" alt="" />
              </Link>
              <Menu1 />
              <Menu2 />
              <div className="mt-10">
                <Link
                  to="/contacto"
                  className="btn text-xs md:text-normal hover:bg-white hover:text-title"
                >
                  Comienza tu campaña
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const Menu1 = () => {
  return (
    <>
      {menu1.map((item) => (
        <Link
          to={item.link}
          key={item.name}
          className="text-white font-bold lg:text-center hover:text-primary"
        >
          {item.name}
        </Link>
      ))}
    </>
  );
};

const Menu2 = () => {
  return (
    <>
      {menu2.map((item) => (
        <Link
          to={item.link}
          key={item.name}
          className="text-white font-bold lg:text-center hover:text-primary"
        >
          {item.name}
        </Link>
      ))}
    </>
  );
};

const menu1 = [
  {
    name: "Inicio",
    link: "/",
  },
  {
    name: "Conócenos",
    link: "/conocenos",
  },
  {
    name: "Medios",
    link: "/medios",
  },
];

const menu2 = [
  {
    name: "Proyectos",
    link: "/proyectos",
  },
  {
    name: "OBP",
    link: "/obp",
  },
];

export default Header;
