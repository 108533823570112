import React from "react";
import { Link } from "react-router-dom";
const HomeIndoor = () => {
	return (
		<section className="lg:pt-[100px] lg:pb-[75px] relative overflow-hidden">
			<div
				className="w-[36%] max-w-[693px] h-full absolute top-0 left-0"
				style={{
					background:
						"url(/img/about/history-shape.png) repeat top right / contain",
				}}
			></div>
			<div className="container relative z-10">
				<div className="flex flex-wrap gap-10 justify-between items-end">
					<div className="w-full lg:max-w-[484px] mr-auto">
						<h2 className="text-xl sm:text-2xl font-bold mb-5 md:mb-[22px] md:text-4xl xl:text-[64px] xl:leading-[78px]">
							Indoor
						</h2>
						<div className="sm:text-md sm:leading-[22px] font-medium">
							<p className="mb-8 xl:mb-10 2xl:mb-[149px]">
								Nuestra oferta de medios indoor abarca una amplia gama
								de soluciones publicitarias efectivas. Colocamos tu
								marca dentro de los principales aeropuertos y centros
								comerciales de todo el país
							</p>
							<Link
								to="/medios"
								className="btn text-xs px-4 md:text-md md:h-[50px] md:min-w-[280px] lg:text-[22px] lg:h-[60px] lg:min-w-[380px] bg-title text-primary hover:bg-primary hover:text-title"
							>
								Explora tu campaña indoor
							</Link>
						</div>
					</div>
					<div className="w-0 flex-grow max-w-[678px] mx-auto">
						<div className="grid grid-cols-2 gap-6 md:gap-9 lg:gap-[58px] place-items-center">
							{data.map((item, index) => (
								<div
									key={index}
									className="relative before:absolute before:inset-0"
								>
									<img src={item.img} className="w-full" alt="" />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
const data = [
	{
		img: "/img/about/outdoor/aeropuertos.png",
	},
	{
		img: "/img/about/outdoor/centroscom.png",
	},
];
export default HomeIndoor;
