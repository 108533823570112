import React from "react";
import arrow from "../img/arrow.png";
import eye from "../img/eye.png";
import img1 from "../img/how/1.png";
import img2 from "../img/how/2.png";
import img3 from "../img/how/3.png";
const HowItWorks = () => {
	return (
		<section className="py-[100px] 2xl:pb-[229px] 2xl:pt-[118px] relative overflow-hidden">
			<img
				src={eye}
				className="hidden lg:block absolute top-16 2xl:top-[120px] xl:left-[calc(50%+270px)] 2xl:left-[calc(50%+470px)]"
				alt=""
			/>
			<div className="container 2xl:max-w-[1388px]">
				<h1 className="text-3xl sm:text-5xl md:text-6xl xl:text-[50px] xl:leading-[64px] 2xl:text-[64px] 2xl:leading-[78px] font-bold mb-8 flex items-center gap-2">
					¿Cómo funciona?{" "}
					<img
						src={arrow}
						className="max-md:w-[50px] max-sm:w-[40px]"
						alt=""
					/>
				</h1>
				<div className="sm:text-md xl:text-lg 2xl:text-xl font-medium mb-12 md:mb-20 xl:mb-[130px]">
					Out Of Home Bit Planner (OBP) es una herramienta avanzada que
					utiliza tecnología de inteligencia artificial para optimizar la
					selección y gestión de espacios publicitarios.
				</div>
			</div>
			<div className="container 2xl:max-w-[1428px]">
				<div className="grid grid-cols-1 md:grid-cols-3 gap-6 gap-y-12">
					{data.map((item, index) => (
						<div className="relative max-w-[390px] mx-auto" key={index}>
							<div className="before:absolute before:inset-0 relative max-md:w-[90px]">
								<img src={item.img} className="max-w-full" alt="" />
							</div>
							<h2 className="text-lg sm:text-xl xl:text-[40px] xl:leading-[49px] font-bold my-5 lg:mt-10 lg:mb-9">
								{item.title}
							</h2>
							<p className="sm:text-md font-medium">{item.text}</p>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

const data = [
	{
		img: img1,
		title: "Selección de sitios",
		text: "Al iniciar una campaña, OBP analiza tus objetivos y presupuesto. Utilizando algoritmos de inteligencia artificial, selecciona los mejores sitios publicitarios que maximicen la visibilidad y el impacto de tu mensaje.",
	},
	{
		img: img2,
		title: "Monitoreo en tiempo real",
		text: "Una vez que los sitios están confirmados y la campaña está en marcha, OBP te permite visualizar y supervisar todo el proceso en tiempo real. ",
	},
	{
		img: img3,
		title: "Reporte y optimización",
		text: "OBP te permite evaluar el éxito de la campaña y hacer ajustes necesarios para futuras iniciativas, asegurando una mejora continua y una gestión eficiente de tus recursos publicitarios.",
	},
];

export default HowItWorks;
