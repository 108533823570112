import React from "react";
import Campaign from "../../components/Campaign";
import Layout from "../../components/Layout";
import Indoor from "./components/Indoor";
import Outdoor from "./components/Outdoor";
import ProyectosBanner from "./components/ProyectosBanner";

const Proyectos = () => {
	return (
		<Layout>
			<ProyectosBanner />
			<Outdoor />
			<Indoor />
			<Campaign />
		</Layout>
	);
};

export default Proyectos;
