import React from "react";
import Border from "../../components/Border";
import Campaign from "../../components/Campaign";
import Layout from "../../components/Layout";
import Banner from "./components/Banner";
import Feature from "./components/Feature";
import HowItWorks from "./components/HowItWorks";
import Technologia from "./components/Technologia";

const Obp = () => {
	return (
		<Layout>
			<Banner />
			<Feature />
			<Technologia />
			<HowItWorks />
			<Border />
			<Campaign />
		</Layout>
	);
};

export default Obp;
