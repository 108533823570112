import React from "react";
import Border from "../../components/Border";
import Campaign from "../../components/Campaign";
import Layout from "../../components/Layout";
import MediosBanner from "./components/ConocenosBanner";
import History from "./components/History";
import Mission from "./components/Mission";
import PartnerSlider from "./components/PartnerSlider";
import QuienesSomos from "./components/QuienesSomos";

const Conocenos = () => {
	return (
		<Layout>
			<MediosBanner />
			<QuienesSomos />
			<PartnerSlider />
			<Mission />
			<Border />
			<History />
			<Border />
			<Campaign />
		</Layout>
	);
};

export default Conocenos;
