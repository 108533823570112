import React from "react";
import { Link } from "react-router-dom";
const HomeOutdoor = () => {
	return (
		<section className="lg:pt-[100px] pb-[75px]">
			<div className="container">
				<div className="flex flex-wrap gap-10 justify-between items-end">
					<div className="w-full lg:max-w-[484px]">
						<h2 className="text-xl sm:text-2xl font-bold mb-5 md:mb-[22px] md:text-4xl xl:text-[64px] xl:leading-[78px]">
							Outdoor
						</h2>
						<div className="sm:text-md sm:leading-[22px] font-medium">
							<p className="mb-8 xl:mb-10 2xl:mb-[149px]">
								Contamos con más de 9,000 sitios a nivel nacional
								estratégicamente ubicados para maximizar la visibilidad
								de tu marca
							</p>
							<Link
								to="/medios"
								className="btn text-xs px-4 md:text-md md:h-[50px] md:min-w-[280px] lg:text-[22px] lg:h-[60px] lg:min-w-[380px]"
							>
								Explora tu campaña outdoor
							</Link>
						</div>
					</div>
					<div className="w-0 flex-grow max-w-[858px]">
						<div className="grid grid-cols-2 md:grid-cols-3 gap-6 md:gap-9">
							{data.map((item, index) => (
								<div
									key={index}
									className="relative before:absolute before:inset-0"
								>
									<img src={item.img} className="w-full" alt="" />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
const data = [
	{
		img: "/img/about/indoor/muros.png",
	},
	{
		img: "/img/about/indoor/espectaulares.png",
	},
	{
		img: "/img/about/indoor/carteleras.png",
	},
	{
		img: "/img/about/indoor/autobuses.png",
	},
	{
		img: "/img/about/indoor/puentespea.png",
	},
	{
		img: "/img/about/indoor/pantallasdig.png",
	},
];
export default HomeOutdoor;
