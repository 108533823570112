import React from "react";
import { icons } from "../../../components/Icons";

const ConocenosBanner = () => {
	return (
		<section
			className="banner-section min-h-[calc(100vh-100px)] py-[120px] xl:min-h-[calc(100vh-150px)] flex flex-col justify-center 2xl:min-h-[968px]"
			style={{
				background: `url(/img/proyectos/banner-bg-3.png) no-repeat center center / cover`,
			}}
		>
			<div className="container">
				<h1 className="text-4xl sm:text-5xl md:text-6xl xl:text-[50px] xl:leading-[64px] 2xl:text-[64px] 2xl:leading-[78px] font-bold text-center">
					<span className="bg-primary rounded-lg lg:rounded-[20px] px-4 ml-3 inline-block lg:inline">
						Tecnología en
					</span>
					<span className="text-primary block mt-2">
						publicidad estratégica
					</span>
				</h1>
			</div>
			<img
				src="/img/proyectos/banner-shape1.png"
				className="absolute max-lg:w-20 right-[40px] top-[30px] lg:right-[100px] lg:top-[90px]"
				alt=""
			/>
			<div className="absolute right-0 bottom-0 w-[80px] h-[200px] rounded-tl-[80px] lg:w-[174px] lg:h-[349px] bg-primary lg:rounded-tl-[174px]"></div>
			<img
				src="/img/proyectos/banner-shape3.png"
				className="absolute max-lg:w-20 left-[40px] bottom-[30px] lg:left-[100px] lg:bottom-[97px]"
				alt=""
			/>
			<span className="absolute left-1/2 -translate-x-1/2 bottom-12 xl:bottom-[60px]">
				{icons.angledown}
			</span>
		</section>
	);
};

export default ConocenosBanner;
