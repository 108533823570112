import React from "react";
import Border from "../../components/Border";
import Campaign from "../../components/Campaign";
import Layout from "../../components/Layout";
import ComenzarCampana from "./components/ComenzarCampana";
import Indoor from "./components/Indoor";
import MediosBanner from "./components/MediosBanner";
import Outdoor from "./components/Outdoor";

const Medios = () => {
	return (
		<Layout>
			<MediosBanner />
			<Outdoor />
			<Indoor />
			<ComenzarCampana />
			<Border />
			<Campaign />
		</Layout>
	);
};

export default Medios;
