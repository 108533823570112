import React from "react";
import DescubreObp from "../../components/DescubreObp";
import Layout from "../../components/Layout";
import PartnerSlider from "../conocenos/components/PartnerSlider";
import Banner from "./components/Banner";
import HomeIndoor from "./components/HomeIndoor";
import HomeOutdoor from "./components/HomeOutdoor";
import Innovacion from "./components/Innovacion";
import MediosCta from "./components/MediosCta";
import StartCampaign from "./components/StartCampaign";

const Home = () => {
	return (
		<Layout>
			<Banner />
			<MediosCta />
			<Innovacion />
			<HomeOutdoor />
			<HomeIndoor />
			<DescubreObp />
			<PartnerSlider />
			<StartCampaign />
		</Layout>
	);
};

export default Home;
