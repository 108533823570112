import React from "react";
import { Link } from "react-router-dom";
import TextTransition, { presets } from "react-text-transition";
import img1 from "../img/1.png";
import img2 from "../img/2.png";
import bg from "../img/banner-bg.png";

const Banner = () => {
	const [open, setOpen] = React.useState(false);
	return (
		<section
			className="py-[120px] pb-[220px] xl:pt-[253px] 2xl:pt-[353px] xl:pb-[247px] relative overflow-hidden"
			onMouseOver={setOpen}
			style={{
				background: `url(${bg}) no-repeat center center / cover`,
			}}
		>
			<div className="container relative z-10">
				<h1 className="text-4xl sm:text-5xl md:text-6xl xl:text-[50px] xl:leading-[64px] 2xl:text-[64px] 2xl:leading-[78px] font-bold mb-4">
					Tu marca en <TextAnimated />
				</h1>
				<div className="sm:text-md md:text-lg 2xl:text-xl md:font-medium mb-12 2xl:mb-[76px] lg:max-w-[660px] xl:max-w-[660px] 2xl:max-w-[780px]">
					<p className="mb-6">
						Impulsamos visibilidad de marcas con tecnología avanzada,
						medios innovadores y seguimiento transparente en tiempo real
						para <strong>optimizar campañas</strong>
					</p>
				</div>
				<Link
					to="/medios"
					className="btn text-xs px-4 md:text-[22px] md:h-[60px] md:min-w-[220px]"
				>
					Explora tu siguiente campaña
				</Link>
			</div>
			<img
				className="absolute bottom-0 right-0 max-lg:max-w-[360px] max-md:max-w-[300px] max-sm:max-w-[230px]"
				src={open ? img2 : img1}
				alt=""
			/>
		</section>
	);
};
const texts = [
	"muros",
	"espectaculares",
	"carteleras",
	"autobuses",
	"pantallas digitales",
	"centros comerciales",
];

const TextAnimated = () => {
	const [index, setIndex] = React.useState(0);
	React.useEffect(() => {
		const intervalId = setInterval(
			() => setIndex((index) => index + 1),
			3000
		);
		return () => clearTimeout(intervalId);
	}, []);
	return (
		<>
			<TextTransition
				springConfig={presets.wobbly}
				className="text-primary !block sm:!inline-block"
			>
				{texts[index % texts.length]}
			</TextTransition>
		</>
	);
};
export default Banner;
