import React from "react";
import img1 from "../img/feature/1.png";
import img2 from "../img/feature/2.png";
import img3 from "../img/feature/3.png";
import img4 from "../img/feature/4.png";
import img5 from "../img/feature/5.png";
import shape from "../img/shape.png";
const Feature = () => {
	return (
		<>
			<div className="py-12 xl:pt-[66px] xl:pb-[70px]">
				<div className="container">
					<div className="flex flex-wrap md:flex-nowrap justify-around gap-6 lg:gap-8">
						{data?.map((item) => (
							<div
								className="before:absolute before:inset-0 relative max-md:max-w-[130px]"
								key={item.img}
							>
								<img src={item.img} className="max-w-full" alt="" />
							</div>
						))}
					</div>
				</div>
			</div>
			<div
				className="h-16 xl:h-[82px] opacity-20"
				style={{
					background: `url(${shape}) repeat-x center center / contain`,
				}}
			></div>
		</>
	);
};

const data = [
	{
		img: img1,
	},
	{
		img: img2,
	},
	{
		img: img3,
	},
	{
		img: img4,
	},
	{
		img: img5,
	},
];

export default Feature;
