import React from "react";
import { Link } from "react-router-dom";
import img1 from "../img/1.png";
import img2 from "../img/2.png";

const Banner = () => {
	const [open, setOpen] = React.useState(false);
	return (
		<section
			className="py-[90px] pb-[300px] xl:pt-[200px] xl:pb-[205px] relative overflow-hidden"
			onMouseOver={setOpen}
		>
			<div className="container relative z-10">
				<h1 className="text-4xl sm:text-5xl md:text-6xl xl:text-[50px] xl:leading-[64px] 2xl:text-[64px] 2xl:leading-[78px] font-bold mb-8">
					OBP:{" "}
					<span className="text-primary"> Resultados inteligentes</span>{" "}
					<br className="hidden lg:inline-block" /> y visibilidad
					garantizada
				</h1>
				<div className="text-md md:text-lg 2xl:text-xl 2xl:leading-[29px] md:font-medium mb-16 2xl:mb-[112px] lg:max-w-[660px] xl:max-w-[860px] 2xl:max-w-[996px]">
					<p className="mb-6">
						Out Of Home Bit Planner (OBP) transforma la planificación y
						ejecución de tus campañas publicitarias. OBP selecciona los
						mejores sitios para tus anuncios según tus objetivos y
						presupuesto, garantizando resultados óptimos.
					</p>
					<p>
						Monitorea todo el proceso en tiempo real, desde la impresión
						hasta la instalación, permitiendo observar de manera
						transparente cada fase de la preparación de la campaña.
					</p>
				</div>
				<Link
					to="#"
					className="btn text-xs px-4 md:text-[22px] md:h-[60px] md:min-w-[220px]"
				>
					Descubre OBP
				</Link>
			</div>
			<img
				className="absolute bottom-0 right-0 max-2xl:max-w-[700px] max-xl:max-w-[560px] max-md:max-w-[460px] max-sm:max-w-[400px]"
				src={open ? img2 : img1}
				alt=""
			/>
		</section>
	);
};

export default Banner;
