import React from "react";
import { Link } from "react-router-dom";
import DescubreObp from "../../../components/DescubreObp";
const ComenzarCampana = () => {
	return (
		<div className="relative">
			<div className="container relative z-[10] py-20 lg:pt-[190px] lg:pb-[102px] 2xl:max-w-[1506px]">
				<div className="flex flex-wrap lg:flex-nowrap justify-between items-center gap-12">
					<div className="flex-grow lg:max-w-[760px]">
						<h2 className="text-xl sm:text-2xl font-bold mb-10 md:mb-16 xl:mb-[104px] md:text-4xl xl:text-[64px] xl:leading-[1.5]">
							¿Tu campaña necesita una implementación especial?
						</h2>
						<div className="sm:text-md sm:leading-[22px] font-medium">
							<Link
								to="/contacto"
								className="btn text-xs px-4 md:text-md md:h-[50px] md:min-w-[280px] lg:text-[22px] lg:h-[60px] lg:min-w-[380px]"
							>
								Comenzar campaña
							</Link>
						</div>
					</div>
					<div className="flex-grow lg:max-w-[554px] font-medium">
						<p className="mb-4 text-md sm:text-lg xl:text-xl">
							Si tu campaña necesita una implementación especial, nuestro
							equipo creativo te apoya generando propuestas para llevar
							tu campaña la siguiente nivel.
						</p>
						<p className="text-black sm:text-md">
							Podemos enriquecer tus anuncios con innovaciones como luces
							LED o efectos tridimensionales, asegurando que tu mensaje
							no solo sea visto, sino también recordado por su impacto
							visual único y distintivo.{" "}
							<strong>
								Esta opción es perfecta para marcas que buscan
								destacarse de manera significativa en el mercado.
							</strong>
						</p>
					</div>
				</div>
			</div>
			<div className="bg-[#FFFDEA]">
				<DescubreObp />
			</div>
			<div
				className="absolute hidden lg:block md:w-[172px] h-full z-[1] right-0 top-0 overflow-hidden"
				style={{
					background: `url('/img/descubre-obp/comenzar-campana-shape.png') repeat-y top center / contain`,
				}}
			></div>
		</div>
	);
};

export default ComenzarCampana;
