import React from "react";

const Mission = () => {
	return (
		<section className="py-[80px] md:pb-[180px] relative overflow-hidden">
			<div className="container relative z-10">
				<img
					src="/img/eye.png"
					className="mb-8 md:mb-12 max-md:w-[100px]"
					alt=""
				/>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-10">
					<div className="mission-item max-w-[620px] me-4">
						<div className="relative z-10">
							<h5 className="text-xl sm:text-2xl md:text-[40px] font-bold">
								Misión
							</h5>
							<div className="text-md sm:text-lg lg:text-xl font-medium mt-[22px] max-w-[467px]">
								Proveer soluciones en publicidad exterior que cumplan
								con las necesidades de las marcas, brindando un servicio
								eficaz y transparente.
							</div>
						</div>
					</div>
					<div className="mission-item max-w-[620px] bg-opacity-[0.32] me-4">
						<div className="relative z-10">
							<h5 className="text-xl sm:text-2xl md:text-[40px] font-bold">
								Visión
							</h5>
							<div className="text-md sm:text-lg lg:text-xl font-medium mt-[22px] max-w-[467px]">
								Ser líderes en publicidad exterior a nivel mundial y que
								OBP sea la herramienta de planeación de medios número 1
								en el mercado.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="hidden sm:block absolute right-0 bottom-0 w-[80px] h-[200px] rounded-tl-[80px] lg:w-[174px] lg:h-[349px] bg-primary lg:rounded-tl-[174px]"></div>
		</section>
	);
};

export default Mission;
