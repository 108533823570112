import React from "react";
import img from "../img/technologia.png";
const Technologia = () => {
	return (
		<section className="pt-10 sm:pt-20 2xl:pt-[118px] pb-[9px]">
			<div className="border-b-[3px] border-black">
				<div className="flex flex-wrap-reverse lg:flex-nowrap items-end gap-4 gap-y-10 px-6 lg:pl-0 xl:pe-4">
					<div className="w-full lg:max-w-[500px] xl:max-w-[700px] mb-[-9px]">
						<img src={img} alt="" />
					</div>
					<div className="w-0 flex-grow xl:max-w-[913px]">
						<h2 className="text-lg sm:text-2xl font-bold text-primary mb-9 md:text-4xl xl:text-[40px] xl:leading-[49px] 2xl:-translate-x-8">
							Tecnología en{" "}
							<span className="inline-block bg-primary text-dark rounded-[15px] px-3 pb-1">
								publicidad estratégica
							</span>
						</h2>
						<div className="sm:text-md xl:text-lg 2xl:text-xl font-medium lg:pb-20 2xl:pb-[220px]">
							<p className="mb-8">
								Enfrentar el reto de localizar los espacios
								publicitarios más efectivos y gestionar campañas
								eficientemente puede ser complicado. Out Of Home Bit
								Planner (OBP) es nuestra solución tecnológica que
								transforma estos desafíos en éxito asegurado.
							</p>
							<p>
								Mediante inteligencia artificial, OBP selecciona
								óptimamente los sitios de publicidad basándose en tus
								objetivos y presupuesto, garantizando una ejecución
								estratégica y rentable.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Technologia;
