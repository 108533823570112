import React from "react";
import { Link } from "react-router-dom";
const DescubreObp = () => {
	return (
		<section className="pt-20 lg:pt-[160px] 2xl:pt-[240px] pb-[200px] lg:pb-[75px] relative overflow-hidden z-10">
			<div className="container 2xl:max-w-[1480px] relative z-10">
				<div className="w-full  lg:max-w-[500px] xl:max-w-[668px] 2xl:max-w-[768px]">
					<h5 className="sm:text-md font-bold md:text-[22px] md:leading-[27px] mb-[22px]">
						Innovación tecnológica
					</h5>
					<h2 className="text-xl sm:text-2xl font-bold mb-5 md:text-4xl xl:text-[64px] xl:leading-[78px] text-primary">
						OBP
					</h2>
					<div className="sm:text-md sm:leading-[22px] font-medium">
						<p className="mb-6">
							Nuestra plataforma OBP (Out Of Home Bit Planner) permite
							una planeación detallada y personalizada de las campañas,
							optimizando la selección de medios y ubicaciones basadas en
							análisis de datos y patrones de tráfico.
						</p>
						<p className="mb-8 xl:mb-10 2xl:mb-[65px]">
							Además, OBP ofrece seguimiento en tiempo real del estatus
							de las campañas. Proporcionando a nuestros clientes la
							capacidad de visualizar paso a paso la planeación,
							ejecución y monitoreo de sus campañas.
						</p>
						<Link
							to="/obp"
							className="btn text-xs px-4 md:text-md md:h-[50px] md:min-w-[280px] lg:text-[22px] lg:h-[60px] lg:min-w-[380px]"
						>
							Descubre OBP
						</Link>
					</div>
				</div>
			</div>
			<img
				className="absolute -bottom-1 right-0 max-xl:w-[500px] max-lg:w-[250px] max-sm:w-[200px] z-[11]"
				src="/img/descubre-obp/excelent.png"
				alt=""
			/>
		</section>
	);
};

export default DescubreObp;
