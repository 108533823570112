import React from "react";
import { Link } from "react-router-dom";

const Indoor = () => {
	return (
		<section className="pt-[102px] pb-[99px] relative overflow-hidden bg-title">
			<div className="container relative z-[11]">
				<h1 className="text-4xl sm:text-5xl md:text-6xl xl:text-[50px] xl:leading-[64px] 2xl:text-[64px] 2xl:leading-[78px] font-bold mb-12 lg:mb-[98px]">
					<div className="flex items-start text-primary gap-4 md:gap-7">
						<img
							src="/img/proyectos/arrow.png"
							alt=""
							className="max-md:w-10"
						/>
						<div className="w-flex max-w-[490px]">
							<span className="block mb-7">Indoor</span>
							<div className="text-normal md:text-md font-medium text-white">
								Nuestra oferta de medios indoor proporciona soluciones
								publicitarias efectivas en entornos controlados y
								espacios cerrados. Estas ubicaciones premium aseguran
								una alta visibilidad y engagement, permitiendo que tu
								marca destaque en un entorno competitivo.
							</div>
						</div>
					</div>
				</h1>
				<div className="flex flex-wrap gap-[22px] justify-evenly">
					{data.map((item, index) => (
						<div
							key={index}
							className="w-[calc(50%-11px)] lg:w-[calc((100%/3)-15px)] outdoor-item"
						>
							<Link
								className="outdoor-item-inner group max-w-[450px] mx-0"
								to="/proyectos"
							>
								<img
									src={item.img1}
									alt=""
									className="w-full group-hover:opacity-0 transition-opacity duration-300"
								/>
								<img
									src={item.img2}
									alt=""
									className="w-full absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
								/>
							</Link>
						</div>
					))}
				</div>
			</div>
			<div className="absolute inset-0 before:absolute before:inset-0 before:z-10">
				<img
					src="/img/proyectos/indoor-2.png"
					alt=""
					className="absolute right-0 top-0 min-h-full w-1/2 max-w-[881px] object-cover object-right-top hidden md:block"
				/>
			</div>
		</section>
	);
};

const data = [
	{
		img1: "/img/indoor/1.png",
		img2: "/img/indoor/hover-1.png",
	},
	{
		img1: "/img/indoor/2.png",
		img2: "/img/indoor/hover-2.png",
	},
];
export default Indoor;
