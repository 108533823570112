import React from "react";
import "swiper/css/bundle";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const PartnerSlider = () => {
	return (
		<section className="py-16 xl:py-[129px] bg-primary bg-opacity-[0.32] partner-slider">
			<div className="container">
				<Swiper
					slidesPerView={5}
					spaceBetween={0}
					autoplay={{
						delay: 0,
						disableOnInteraction: false,
					}}
					speed={3000} // Ajusta la velocidad según sea necesario
					loop={true}
					loopedSlides={data.length} // Asegura una transición fluida
					freeMode={true} // Permite un desplazamiento continuo sin saltos
					breakpoints={{
						0: {
							slidesPerView: 3,
							slidesPerGroup: 1,
						},
						640: {
							slidesPerView: 4,
							slidesPerGroup: 1,
						},
						768: {
							slidesPerView: 5,
							slidesPerGroup: 1,
						},
					}}
					modules={[Autoplay]}
				>
					{data.map((item, index) => (
						<SwiperSlide key={index}>
							<div className="w-[140px] sm:w-[200px] md:w-[220px] 2xl:w-[300px] px-4 sm:px-6">
								<img
									src={item.img}
									className="max-w-full mx-auto !w-[unset]"
									alt=""
								/>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
};

const data = [
	{
		img: "/img/about/partner/1.png",
	},
	{
		img: "/img/about/partner/2.png",
	},
	{
		img: "/img/about/partner/3.png",
	},
	{
		img: "/img/about/partner/4.png",
	},
	{
		img: "/img/about/partner/5.png",
	},
	{
		img: "/img/about/partner/6.png",
	},
	{
		img: "/img/about/partner/7.png",
	},
	{
		img: "/img/about/partner/8.png",
	},
	{
		img: "/img/about/partner/9.png",
	},
	{
		img: "/img/about/partner/10.png",
	},
];

export default PartnerSlider;
