import React from "react";
import { Link } from "react-router-dom";

const MediosCta = () => {
	return (
		<section className="relative">
			<div className="font-semibold text-xl sm:text-2xl md:text-4xl absolute top-8 md:top-16 left-1/2 -translate-x-1/2 text-center">
				Medios
			</div>
			<div className="grid grid-cols-2 sm:grid-cols-2 text-xl sm:text-2xl font-bold md:text-4xl xl:text-[64px] xl:leading-[1.5]">
				<Link
					to="/medios"
					className="bg-primary bg-opacity-[0.32] flex justify-center items-center py-20 md:min-h-[372px] hover:bg-opacity-100 duration-300"
				>
					<span>Outdoor</span>
				</Link>
				<Link
					to="/medios"
					className="bg-primary bg-opacity-60 flex justify-center items-center py-20 md:min-h-[372px] hover:bg-opacity-100 duration-300"
				>
					<span>Indoor</span>
				</Link>
			</div>
		</section>
	);
};

export default MediosCta;
